import React from 'react';
import BoldTitle from './BoldTitle';
import PropTypes from 'prop-types';
import IconComponent from './IconComponent';

const PersonalAttributionSection = ({ personalAttributionData }) => {
  const socialMediaIcons = [
    { key: 'linkedIn', icon: 'linkedin-icon' },
    { key: 'twitter', icon: 'twitter' },
    { key: 'facebook', icon: 'facebook-filled' },
    { key: 'website', icon: 'globe-web-icon' },
  ];

  return (
    <section className="w-full bg-primary-0">
      <div className="w-full max-w-6xl px-6 py-16 m-auto space-y-16 md:py-24">
        <div className="space-y-4">
          <BoldTitle className="leading-snug md:text-center">
            Personal <span className="text-primary">Attribution</span>
          </BoldTitle>
        </div>
        <div className="grid w-full grid-cols-1 gap-6 mt-8 md:gap-8 sm:grid-cols-3 lg:grid-cols-4">
          {personalAttributionData.map((item) => {
            const { id, name, socialLinks } = item;
            const hasAnySocialLink = socialMediaIcons.some(
              (socialMedia) => socialLinks[socialMedia.key],
            );
            return (
              <div
                className="px-5 py-6 space-y-4 text-center shadow-2xl rounded-xl bg-neutral"
                key={id}
              >
                <p className="text-xl font-bold text-accent">{name}</p>
                <div className="flex justify-center gap-3">
                  {hasAnySocialLink ? (
                    <>
                      {socialMediaIcons.map((socialMedia) => {
                        const link = socialLinks[socialMedia.key];
                        return (
                          link && (
                            <a
                              key={socialMedia.key}
                              href={link}
                              rel="nofollow noreferrer"
                              target="_blank"
                              className="flex items-center p-0.5 rounded text-neutral bg-primary hover:bg-primary-900"
                              onClick={(e) => {
                                e.stopPropagation();
                              }}
                            >
                              <IconComponent
                                className="w-5 h-5"
                                name={socialMedia.icon}
                              />
                            </a>
                          )
                        );
                      })}
                    </>
                  ) : (
                    <IconComponent
                      name="globe-web-icon"
                      className="text-neutral-4"
                    />
                  )}
                </div>
              </div>
            );
          })}
        </div>
        <div className="w-full max-w-2xl m-auto text-sm text-center md:text-base">
          <p className="leading-7">
            Many other organisations and individual brothers and sisters have
            also helped us by sharing feedback, preparing content, translating
            app UI, and reporting bugs. May Allah reward them all, Ameen.
          </p>
        </div>
      </div>
    </section>
  );
};

PersonalAttributionSection.propTypes = {
  personalAttributionData: PropTypes.array.isRequired,
};

export default PersonalAttributionSection;
